import { registerApplication, start } from "single-spa";

registerApplication({
  name: "@erm/erm-ui",
  app: () => System.import("@erm/erm-ui"),
  activeWhen: (location) => location.hash.startsWith("#/"),
});

registerApplication({
  name: "@erm/erm-auth",
  app: () => System.import("@erm/erm-auth"),
  activeWhen: (location) => location.hash.startsWith("#/auth"),
});

registerApplication({
  name: "@erm/erm-menu",
  app: () => System.import("@erm/erm-menu"),
  activeWhen: (location) => location.hash.startsWith("#/erm"),
});

registerApplication({
  name: "@erm/erm-home-new",
  app: () => System.import("@erm/erm-home-new"),
  activeWhen: (location) => location.hash.startsWith("#/erm/home-new"),
});

registerApplication({
  name: "@erm/erm-mis-sitios",
  app: () => System.import("@erm/erm-mis-sitios"),
  activeWhen: (location) => location.hash.startsWith("#/erm/mis-sitios"),
});
registerApplication({
  name: "@erm/erm-gestor-documetal",
  app: () => System.import("@erm/erm-gestor-documetal"),
  activeWhen: (location) => location.hash.startsWith("#/erm/gestor-documental"),
});
registerApplication({
  name: "@erm/erm-perfiles",
  app: () => System.import("@erm/erm-perfiles"),
  activeWhen: (location) => location.hash.startsWith("#/erm/perfiles"),
});

registerApplication({
  name: "@erm/erm-falapedia",
  app: () => System.import("@erm/erm-falapedia"),
  activeWhen: (location) => location.hash.startsWith("#/erm/falapedia"),
});

registerApplication({
  name: "@erm/erm-beneficios",
  app: () => System.import("@erm/erm-beneficios"),
  activeWhen: (location) => location.hash.startsWith("#/erm/beneficios"),
});

// registerApplication({
//   name: "@erm/erm-giftcard",
//   app: () => System.import("@erm/erm-giftcard"),
//   activeWhen: (location) => location.hash.startsWith("#/erm/admin"),
// });

registerApplication({
  name: "@erm/erm-admin-giftcard",
  app: () => System.import("@erm/erm-giftcard"),
  activeWhen: (location) => location.hash.startsWith("#/erm/giftcard"),
});

registerApplication({
  name: "@erm/erm-biblioteca",
  app: () => System.import("@erm/erm-biblioteca"),
  activeWhen: (location) => location.hash.startsWith("#/erm/biblioteca"),
});

registerApplication({
  name: "@erm/ejemplo",
  app: () => System.import("@erm/ejemplo"),
  activeWhen: (location) => location.hash.startsWith("#/erm/ejemplo"),
});

registerApplication({
  name: "@erm/erm-administrador-documentos",
  app: () => System.import("@erm/erm-administrador-documentos"),
  activeWhen: (location) =>
    location.hash.startsWith("#/erm/administrador-documentos"),
});

start();

//Only for PROD
window.addEventListener("load", (_) => {
  const urlActive = window.location.href;
  const urlStatic = "https://mi.falabella.com/";
  if (urlActive === urlStatic) {
    window.location.href = urlActive.concat("#/auth");
  }
  const urlStatic2 = "https://mi.falabella.com";
  if (urlActive === urlStatic2) {
    window.location.href = urlActive.concat("/#/auth");
  }
});
//End Only for PROD

// window.addEventListener("single-spa:app-change", (_) => {
//   const urlActive = window.location.href;
//   isDisplay("single-spa-application:@erm/erm-menu", urlActive);
// });
// function isDisplay(nameId: string, urlActive) {
//   const usuario = document.getElementById(nameId);

//   if (sessionStorage.getItem("context")) {
//     usuario.style.display = "block";
//   } else {
//     usuario.style.display = "none";
//   }
// }
